/**

dP                         dP                         dP
88                         88                         88
88d888b. .d8888b. 88d888b. 88d888b. .d8888b. .d8888b. 88d888b.
88'  `88 88ooood8 88'  `88 88'  `88 88'  `88 88'  `"" 88'  `88
88.  .88 88.  ... 88       88.  .88 88.  .88 88.  ... 88    88
88Y8888' `88888P' dP       88Y8888' `88888P8 `88888P' dP    dP
ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
                                                  Configuration

**/

$color-font: #000;
$breakpoint-small: 0;
$breakpoint-mid: 800px;
$breakpoint-normal: 1024px;
$breakpoint-desktop: 1320px;

$font-normal: 'Fira Sans';
$font-light: $font-normal;
$font-medium: $font-normal;
$font-bold: $font-normal;
