@import "../../../node_modules/typeface-fira-sans/index.css";
@import "../../../node_modules/animate.css/animate.css";

$fa_font_path: "../../../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

@import "config/config";

body {
  background: #fff;
  color: $color-font;
  font-family: $font-normal, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 200;
  margin: 0;

  &.background {
    background: url('../images/bb_bg_02.jpg') no-repeat fixed center;
    background-size: cover;
  }
}

body,
html {
  height: 100%;
}
h1, h2, h3, h4, h5, h6, strong, b {
  font-weight: 300;
}

a {
  color: $color-font;
  text-decoration: none;
}

ul {
  margin: 20px 10px;
  li {
    margin: 3px 0;
  }
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.site {
  @import "site/base";
  @import "site/content";
  @import "site/header";
  @import "site/footer";
  @import "site/cookie";
}

.partial {
  @import "partial/home";
  @import "partial/team";
  @import "partial/jobs";
  @import "partial/event";
  @import "partial/lightbox";
  // @import "partial/login";
}
