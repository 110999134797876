&-header {
  background: url('../images/hr.png') repeat-x bottom;
  left: 0;
  padding: 30px 0 28px;
  margin-bottom: 40px;
  position: fixed;
  width: 100%;
  z-index: 5;

  &_scrolled {
    background-color: white;
  }
  @media (min-width: $breakpoint-mid) {
    background-image: none;
    padding: 40px 0 0;
  }

  &_wrapper {
    display: table;
    margin: 0 auto;
    max-width: $breakpoint-desktop - 40px;
    width: 100%;
    position: relative;
  }

  &_spacer {
    height: 140px;

    @media (min-width: $breakpoint-mid) {
      height: 200px;
    }
  }

  &_logo,
  &_meta,
  &_navigation__kicker {
    display: table-cell;
    vertical-align: top;
  }

  &_meta,
  &_navigation__kicker {

    @media (min-width: $breakpoint-mid) {
      background: url('../images/hr.png') repeat-x bottom;
      padding-bottom: 30px;
    }
  }

  &_logo {
    padding-left: 20px;
    width: 120px;

    @media (min-width: $breakpoint-desktop) {
      padding-left: 0;
    }

    @media (min-width: $breakpoint-mid) {
      width: 220px;
    }

    a {
      background: url('../images/logo.svg') no-repeat;
      display: block;
      font-size: 0;
      height: 33px;
      width: 140px;

      @media (min-width: $breakpoint-mid) {
        background: url('../images/logo.svg') no-repeat;
        height: 49px;
        width: 140px;
      }
    }
  }
  &_meta {
    font-size: 12px;
    text-align: right;
    padding-right: 100px;

    nav {
      display: none;

      @media (min-width: $breakpoint-mid) {
        display: table-cell;
      }
    }

    ul {
      margin: 34px 0 0;
      padding: 0;
    }
    li {
      border-right: 1px solid $color-font;
      display: inline-block;
      list-style: none;
      margin-right: 6px;
      padding-right: 11px;

      &:last-child {
        border-right: 0;
      }
    }

    &__mobile {
      margin-top: 4px;

      @media (min-width: $breakpoint-mid) {
        display: none;
      }

      a {
        display: inline-block;
        height: 23px;
        width: 24px;
        background: url("../images/info.svg") no-repeat center;
        span {
          font-size: 0;
        }
      }
    }
  }

  &_navigation {
    bottom: 0;
    background-color: $color-font;
    left: 0;
    margin: 0;
    overflow-y: auto;
    padding: 0;
    position: fixed;
    right: 0;
    z-index: 99;
    top: 0;

    &__menu {
      box-sizing: border-box;
      color: #fff;
      font-size: 30px;
      line-height: 72px;
      max-width: 1320px;
      margin: 0 auto;
      padding: 60px 56px 30px 0;
      text-align: right;
      text-transform: uppercase;

      @media (min-width: $breakpoint-mid) {
        font-size: 44px;
      }

      li {
        list-style: none;
      }

      a {
        color: #fff;

        &:hover,
        .active {
          color: #c2ccd1;
        }
      }
    }

    &__button {
      display: inline-block;
      line-height: 43px;
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 45px;
      z-index: 100;
      span {
        margin: -7px 0;
      }
      @media (min-width: $breakpoint-desktop){
        right: 0;
      }
    }

    &-open {
      display: inline-block;
      width: 43px;
      height: 43px;
      background: url("../images/kicker.svg") no-repeat center;
      font-size: 0;
    }

    &-close {
      font-size: 0;
      background: url("../images/cross.svg") no-repeat center;
      width: 43px;
      height: 43px;
      display: inline-block;
    }
  }
}
