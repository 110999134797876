&-footer {
  margin: -183px 20px 0;
  padding: 100px 0 0;
  text-align: center;

  @media (min-width: $breakpoint-mid) {
    padding: -183px 0 0;
    text-align: right;
  }

  nav {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    list-style: none;
    margin-bottom: 30px;
    padding-left: 18px;

    @media (min-width: $breakpoint-mid) {
      padding-left: 10px;
    }

    &:first-child {
      display: block;
      font-size: 12px;
      margin-bottom: 30px;

      @media (min-width: $breakpoint-mid) {
        display: inline-block;
        margin: 0 20px 0;
        vertical-align: top;
      }

      p {
        margin: 0;
      }
    }
  }
  &_link {
    &-co2, &-facebook,
    &-twitter {
      display: inline-block;
      height: 40px;
      width: 40px;
      transition: all 200ms ease;
      span {
        font-size: 0;
      }
      &:hover {
        transform: scale(1.5) rotate(-20deg);
      }

      @media (min-width: $breakpoint-mid) {
        height: 21px;
        width: 21px;
        margin-top: -3px;
      }
    }

    &-co2 {
      background: url("../images/co2.svg")no-repeat center;
      background-size: contain;
    }
    &-facebook {
      background: url("../images/facebook.svg")no-repeat center;
      background-size: contain;

    }
    &-twitter {
      background: url("../images/twitter.svg")no-repeat center;
      background-size: contain;
    }
  }
}