// animate.css is required
  
&-lightbox{
    &_wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        background-color:rgba(256,256,256,0.6);
        z-index: 99;
        left: 0;
        right: 0;
        display: flex;
    }
    
    &_item {
        transition: transform 0.1s linear;
        &:hover {
            transform: scale(1.05);
        }
    }

    &_stage {
        &-left, &-middle, &-right {
           height: 100vh;
        }

        &-left, &-right {
            width: 40%;
            position: fixed;            
            z-index: 5;
            @media (min-width: $breakpoint-normal) { 
                width: 15%;
                position: relative;
            }
        } 
        &-left{
            left: 0;
        }

        &-right{
            right: 0;
        }

        &-middle {
            width: 95%;
            height: 100vh;
            margin: 0 auto;
            @media (min-width: $breakpoint-normal) { 
                width: 70%;
            }  
        }
    }

    &_preview {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_figure {
        position:relative;
        &-caption {
            background: rgba($color: #000000, $alpha: 0.6);
            color: white;
            padding: 2px 5px;
            position: absolute;
            bottom: 7px;
            left: 0;
            right: 0;
        }

        &.activeNext {
            position: absolute;
            transform: scale(0.2);
            opacity: 0;
        }

        &.active {
            z-index: 2;   
        }

        &.activePrev {
            position: absolute;
            z-index: 3;
            transform: scale(5);
            opacity: 0;
        }

        // how long is animation-duration set that value in vue as "animationDuration"
        &.zoomIn {
            animation-name: zoomIn;
            animation-duration: .4s;
        }
        &.zoomOut {
            animation-name: zoomOut;
            animation-duration: .4s;
        }
    }

    &_thumbs {
        position: absolute;
        bottom: 10%;
        top: auto;
        left: 15%;
        right: 15%;
        z-index: 5;

        &-icon {
            height: 100px;
            width: 100px;
            transition: transform 0.1s linear; 

            &:hover {
                transform: scale(1.05);
            }

            &.active {
                transform: scale(1.1);
            }
        }
    }
    &_button {
        position: absolute;
        border-radius: 50%;
        line-height: 47px;
        width: 50px;
        height: 50px;
        font-size: 36px;
        &:hover {
            color: royalblue;
        }

        &.disabled {
              visibility: hidden;
        }

        &-close {
            right: 20px;
            top: 20px;
            z-index: 6;
        }
        &-prev,
        &-next {
            top: 45%;
            bottom: 55%;
            visibility: hidden;

            @media (min-width: $breakpoint-normal) { 
                visibility:visible;
            }
            
        }

        &-prev {
            left: 30%;
        }

        &-next {
            right: 30%;
        }
    }
}

//Animation 

@keyframes zoomIn {
    0%   {transform: scale(0.2)}
    25%  {transform: scale(0.4); opacity:0.25}
    50%  {transform: scale(0.6); opacity:0.5}
    75%  {transform: scale(0.8); opacity:0.75}
    100% {transform: scale(1); opacity: 1}
}

@keyframes zoomOut {
    0%   {transform: scale(1);}
    25%  {transform: scale(2); opacity:0.75}
    50%  {transform: scale(3); opacity:0.5}
    75%  {transform: scale(4); opacity:0.25}
    100% {transform: scale(5); opacity: 0;}
}
