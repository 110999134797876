&-home {
  width: 100%;

  @media (min-width: $breakpoint-mid) {
    max-width: 400px;
  }

  &_intro {
    margin-bottom: 100px;
    max-width: 400px;
  }

  &_report {
    margin-bottom: 80px;
    max-width: 400px;

    &__item {
      display: table;
      margin-bottom: 20px;
    }

    &__kw,
    &__text {
      display: table-cell;
    }

    &__kw {
      font-family: $font-normal;
      width: 60px;
    }
  }

  &_stats {

    ul {
      margin: 0 0 6px;
      padding: 0;
    }

    li {
      border-right: 1px solid $color-font;
      display: inline-block;
      list-style: none;
      margin-right: 10px;
      padding-right: 14px;

      &:first-of-type {
        border-right: 0;
        display: block;
        font-family: $font-normal;
        margin-bottom: 6px;

        @media (min-width: $breakpoint-mid) {
          border-right: 1px solid $color-font;
          display: inline-block;
          margin-bottom: 0;
        }
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}