&-jobs {
  &__list {
    list-style: none;
    margin: 0;
    padding-left: 0;
    > li {
      margin: 0 0 20px;
    }
  }

  &_item {
    &__title {
      font-size: 18px;
      font-weight: 200;
    }
    &__entry {
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }

      li {
        list-style: none;

        &:before {
          content: '·';
          position: absolute;
          margin-left: -10px;
        }
      }
    }
    &.span {
      font-weight: 300;
    }
  }
}
