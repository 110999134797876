/* fira-sans-100normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Fira Sans Thin '),
    local('Fira Sans-Thin'),
    url('./files/fira-sans-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-100italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Fira Sans Thin italic'),
    local('Fira Sans-Thinitalic'),
    url('./files/fira-sans-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-100italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-200normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Fira Sans Extra Light '),
    local('Fira Sans-Extra Light'),
    url('./files/fira-sans-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-200italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Fira Sans Extra Light italic'),
    local('Fira Sans-Extra Lightitalic'),
    url('./files/fira-sans-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-300normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Fira Sans Light '),
    local('Fira Sans-Light'),
    url('./files/fira-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-300italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Fira Sans Light italic'),
    local('Fira Sans-Lightitalic'),
    url('./files/fira-sans-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-400normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Fira Sans Regular '),
    local('Fira Sans-Regular'),
    url('./files/fira-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-400italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Fira Sans Regular italic'),
    local('Fira Sans-Regularitalic'),
    url('./files/fira-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-500normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Fira Sans Medium '),
    local('Fira Sans-Medium'),
    url('./files/fira-sans-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-500italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Fira Sans Medium italic'),
    local('Fira Sans-Mediumitalic'),
    url('./files/fira-sans-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-600normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Fira Sans SemiBold '),
    local('Fira Sans-SemiBold'),
    url('./files/fira-sans-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-600italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Fira Sans SemiBold italic'),
    local('Fira Sans-SemiBolditalic'),
    url('./files/fira-sans-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-700normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Fira Sans Bold '),
    local('Fira Sans-Bold'),
    url('./files/fira-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-700italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Fira Sans Bold italic'),
    local('Fira Sans-Bolditalic'),
    url('./files/fira-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-800normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Fira Sans ExtraBold '),
    local('Fira Sans-ExtraBold'),
    url('./files/fira-sans-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-800italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Fira Sans ExtraBold italic'),
    local('Fira Sans-ExtraBolditalic'),
    url('./files/fira-sans-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-900normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Fira Sans Black '),
    local('Fira Sans-Black'),
    url('./files/fira-sans-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* fira-sans-900italic - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Fira Sans Black italic'),
    local('Fira Sans-Blackitalic'),
    url('./files/fira-sans-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/fira-sans-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

