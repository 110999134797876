&-content {
  font-size: 18px;

  &_margin {
    @media (min-width: $breakpoint-mid) {
      margin: 0 220px;
    }

    @media (min-width: 1199px) {
      &.internal {
        margin: 0 160px;
      }
    }

  }

  &_button-back {
    margin-bottom: 20px;
    display: inline-block;
  }
  &_row {
    @media (min-width: $breakpoint-desktop) {
      display: table;
    }
  }

  &_col {
    @media (min-width: $breakpoint-desktop) {
      display: table-cell;
      width: 50%;
    }
  }

  &_2col {
    margin: 0;
    padding: 0;

    @media (min-width: $breakpoint-desktop) {
      column-count: 2;
    }

    li {
      display: inline-block;
      list-style: none;
      margin: 0 0 20px;
      padding: 0;
      width: 100%;
    }
  }

  &_meta {
    color: #999999;
    margin-bottom: 40px;
  }

  &_main {
    margin: 0 auto;
    max-width: 1160px;
    width: 100%;
    
    h1,
    h2,
    h3 {
      font-family: $font-normal;
      font-size: 30px;
      margin: 30px 0;

      @media (min-width: $breakpoint-mid) {
        font-size: 40px;
      }
    }
    h4 {
      font-family: $font-normal;
      font-size: 26px;
      margin: 20px 0;
    }

    h1,
    h2,
    h3,
    h4 {
      &.no-margin {
        margin: 0;
      }
    }

    h1:first-of-type {
      margin-top: 0;
    }

    strong {
      font-family: $font-normal;
    }

    pre {
      background: $color-font;
      color: #fff;
      font-family: monospace;
      overflow: auto;
      padding: 20px;
    }

    p {
      letter-spacing: .5px;
      line-height: 25px;
    }

    img {
      height: auto;
      max-width: 100%;
    }

    a {
      text-decoration: underline;
    }
    .no_underline {
      text-decoration: none;
    }
  }
}