&-team {
  @media (min-width: $breakpoint-mid) {
    display: table;
    width: 100%;
  }

  &.isFirst {
    margin-top: 100px;
  }

  &_spacer {
    margin: 50px auto;
    width: 200px;

    hr {
      background: url('../images/hr.png') repeat-x;
      border: 0;
      height: 1px;
    }
  }

  &_image,
  &_text {
    @media (min-width: $breakpoint-mid) {
      display: table-cell;
      vertical-align: top;
    }
  }

  &_image {
    &__portrait {
      display: none;
      max-width: 410px;

      @media (min-width: $breakpoint-mid) {
        display: table-cell;
        width: 410px;
      }
    }

    &__landscape {
      display: table-cell;
      width: 100%;

      @media (min-width: $breakpoint-mid) {
        display: none;
      }
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  &_text {
    @media (min-width: $breakpoint-mid) {
      padding-left: 80px;
    }

    h3.no-margin {
      font-size: 18px;
      margin: -66px 20px 0;

      @media (min-width: $breakpoint-mid) {
        font-size: 30px;
        margin: 0;
      }
    }

    &__meta {
      margin-left: 20px;

      @media (min-width: $breakpoint-mid) {
        margin-left: 0;
      }
    }

    &__about {
      margin-top: 40px;

      @media (min-width: $breakpoint-mid) {
        margin-top: 0;
      }
    }
  }

  dl {
    @media (min-width: $breakpoint-mid) {
      margin-bottom: 0;
      padding: 30px 0 0;
    }
  }

  dt {
    font-family: $font-normal;
    font-weight: 300;

    @media (min-width: $breakpoint-desktop) {
      float: left;
      margin-bottom: 10px;
    }
  }

  dd {
    margin-bottom: 10px;
    margin-left: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: $breakpoint-desktop) {
      margin-left: 330px;
    }

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    figure {
      margin: 0;
    }

    ul {
      margin: 0;
      list-style: none;
      padding-left: 10px;

      li {
        position: relative;
        &:before {
          content: "-";
          position: absolute;
          left: -11px;
        }
      }
    }
  }
}