&-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  color: black;
  font-size: 16px;
  background: rgba(256, 256, 256, .95) url('../images/hr.png') top repeat-x;
  &-wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    padding: 20px 0;
    > p {
      display: inline-block;
    }
    .site-cookie-button {
      display: inline-block;
      border: solid 1px black;
      border-radius: 3px;
      padding: 3px 10px;
      margin-left: 30px;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
}
