&-base {
  &_container {
    box-sizing: border-box;
    height: auto;
    margin: 0 auto;
    max-width: 1280px;
    min-height: 100%;
    padding: 0 20px 180px;
    width: 100%;

    @media (min-width: $breakpoint-desktop) {
      padding: 0 0 180px;
    }
  }
}