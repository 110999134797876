// breakpoints

$breakpoint-medium: 768px;

&-events {
    padding: 20px 0;
    @media (min-width: $breakpoint-medium) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &_card {
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin-bottom: 5px;
        @media (min-width: $breakpoint-medium) {
            width: 49%;
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-normal) {
            width: 32.7%;
        }
    }

    &_detail {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 5px 10px;
        background-color: rgba($color: white, $alpha: 0.6);
        
        @media (min-width: $breakpoint-normal) {
            // top: 240px;
            // transition: top 0.3s linear;
        }

        
    }

    &_headline{
        font-size: 15px;
        margin: 0;
        font-weight: 400;
        
        @media (min-width: $breakpoint-desktop){
            font-size: 18px;
        }
    }

    &_intro {
        font-size: 13px;
        margin: 0;

        @media (min-width: $breakpoint-desktop){
            font-size: 16px;
        }
    }
}

&-event {
    padding:20px 0;
}
